/*
///////////////////////////////////////////////////

Project Name: Drag & Drop Cards by FREEHTML5.co
Author: FREEHTML5.co
Website: http://freehtml5.co
URL: http://freehtml5.co/cards/drag-drop
License: Creative Commons Attribution 3.0 License

///////////////////////////////////////////////////
1.0 BASE
///////////////////////////////////////////////////
*/
html.mobile-menu-expanded {
  overflow: hidden;
}
body {
  background-color: #232939;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, sans-serif;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  font-size: 14px;
  line-height: 26px;
  color: silver;
  font-weight: 400;
}
@media screen and (max-width: 480px) {
  body {
    min-width: 320px;
    float: left;
    width: 100%;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 30px 0;
  padding: 0;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 700;
  color: #262626;
}
p {
  margin-bottom: 30px;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 35px;
  font-weight: 700;
}
a {
  color: #1784fb;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
}
.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, sans-serif;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.btn.btn-md {
  padding: 10px 20px !important;
}
.btn.btn-lg {
  padding: 18px 36px !important;
}
.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}
.btn-primary {
  background: #1784fb;
  color: #fff;
  border: 2px solid #1784fb;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #0477f4 !important;
  border-color: #0477f4 !important;
}
.btn-primary.btn-outline {
  background: transparent;
  color: #1784fb;
  border: 2px solid #1784fb;
}
.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active {
  background: #1784fb;
  color: #fff;
}
.btn-success {
  background: #58ca7e;
  color: #fff;
  border: 2px solid #58ca7e;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #45c46f !important;
  border-color: #45c46f !important;
}
.btn-success.btn-outline {
  background: transparent;
  color: #58ca7e;
  border: 2px solid #58ca7e;
}
.btn-success.btn-outline:hover,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active {
  background: #58ca7e;
  color: #fff;
}
.btn-info {
  background: #1784fb;
  color: #fff;
  border: 2px solid #1784fb;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  background: #0477f4 !important;
  border-color: #0477f4 !important;
}
.btn-info.btn-outline {
  background: transparent;
  color: #1784fb;
  border: 2px solid #1784fb;
}
.btn-info.btn-outline:hover,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active {
  background: #1784fb;
  color: #fff;
}
.btn-warning {
  background: #fed330;
  color: #fff;
  border: 2px solid #fed330;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #fece17 !important;
  border-color: #fece17 !important;
}
.btn-warning.btn-outline {
  background: transparent;
  color: #fed330;
  border: 2px solid #fed330;
}
.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
  background: #fed330;
  color: #fff;
}
.btn-danger {
  background: #fb4f59;
  color: #fff;
  border: 2px solid #fb4f59;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background: #fa3641 !important;
  border-color: #fa3641 !important;
}
.btn-danger.btn-outline {
  background: transparent;
  color: #fb4f59;
  border: 2px solid #fb4f59;
}
.btn-danger.btn-outline:hover,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active {
  background: #fb4f59;
  color: #fff;
}
.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  box-shadow: none;
}
.lnk-primary {
  color: #1784fb;
}
.lnk-primary:hover,
.lnk-primary:focus,
.lnk-primary:active {
  color: #0477f4;
}
.lnk-success {
  color: #58ca7e;
}
.lnk-success:hover,
.lnk-success:focus,
.lnk-success:active {
  color: #45c46f;
}
.lnk-info {
  color: #1784fb;
}
.lnk-info:hover,
.lnk-info:focus,
.lnk-info:active {
  color: #0477f4;
}
.lnk-warning {
  color: #fed330;
}
.lnk-warning:hover,
.lnk-warning:focus,
.lnk-warning:active {
  color: #fece17;
}
.lnk-danger {
  color: #fb4f59;
}
.lnk-danger:hover,
.lnk-danger:focus,
.lnk-danger:active {
  color: #fa3641;
}
a .lnk-icon-right {
  top: 2px;
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover .lnk-icon-right {
  padding-left: 5px;
}
/* Scroll Animations */
@-webkit-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}
@-moz-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}
@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}
.scroll-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 15;
}
.scroll-btn > * {
  display: inline-block;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  color: #ffffff;
  font-family: Arial, sans-serif;
  letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
  color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.scroll-btn .mouse {
  position: relative;
  display: block;
  width: 30px;
  height: 45px;
  margin: 0 auto 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 23px;
}
.scroll-btn .mouse > * {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 2px;
  height: 6px;
  margin: -2px 0 0 -1px;
  background: white;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}
/* Utilities */
.fh5co-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.p-b {
  padding-bottom: 50px;
}
@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none !important;
    width: 100% !important;
    display: block !important;
  }
}
/*
///////////////////////////////////////////////////
2.0 NAVIGATIONS
///////////////////////////////////////////////////
*/
#fh5co-offcanvass {
  position: fixed;
  width: 250px;
  bottom: 0;
  top: 0;
  padding: 20px;
  z-index: 1;
  display: none;
  background: #384047;
  -webkit-backface-visibility: hidden;
}
#fh5co-offcanvass.offcanvass-nav-style-2 .fh5co-special,
#fh5co-offcanvass.offcanvass-nav-style-1 .fh5co-special {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
#fh5co-offcanvass.offcanvass-nav-style-2 .fh5co-special li,
#fh5co-offcanvass.offcanvass-nav-style-1 .fh5co-special li {
  display: inline;
}
#fh5co-offcanvass.offcanvass-nav-style-2 .fh5co-special li a,
#fh5co-offcanvass.offcanvass-nav-style-1 .fh5co-special li a {
  padding: 10px 0;
  float: left;
  margin: 0 20px 0 0;
}
#fh5co-offcanvass > nav {
  position: relative !important;
}
#fh5co-offcanvass.fh5co-offcanvass-left {
  left: 0;
  -moz-transform: translateX(-250px);
  -webkit-transform: translateX(-250px);
  -ms-transform: translateX(-250px);
  -o-transform: translateX(-250px);
  transform: translateX(-250px);
  -webkit-transition: all 0.8s ease-in;
  -moz-transition: all 0.8s ease-in;
  -ms-transition: all 0.8s ease-in;
  -o-transition: all 0.8s ease-in;
  transition: all 0.8s ease-in;
}
.mobile-menu-expanded #fh5co-offcanvass.fh5co-offcanvass-left {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
#fh5co-offcanvass.fh5co-offcanvass-right {
  right: 0;
  -moz-transform: translateX(250px);
  -webkit-transform: translateX(250px);
  -ms-transform: translateX(250px);
  -o-transform: translateX(250px);
  transform: translateX(250px);
  -webkit-transition: all 0.8s ease-in;
  -moz-transition: all 0.8s ease-in;
  -ms-transition: all 0.8s ease-in;
  -o-transition: all 0.8s ease-in;
  transition: all 0.8s ease-in;
}
.mobile-menu-expanded #fh5co-offcanvass.fh5co-offcanvass-right {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
@media screen and (max-width: 768px) {
  #fh5co-offcanvass {
    display: block;
  }
}
#fh5co-offcanvass a {
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-offcanvass a:hover,
#fh5co-offcanvass a:focus,
#fh5co-offcanvass a:active {
  outline: none;
  text-decoration: none;
}
#fh5co-offcanvass .fh5co-logo a {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-offcanvass ul {
  padding: 0;
  margin: 0;
}
#fh5co-offcanvass ul li {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}
#fh5co-offcanvass ul li a {
  padding: 10px 0;
  display: block;
}
#fh5co-offcanvass ul li a:hover {
  color: white;
}
#fh5co-offcanvass ul li a.call-to-action {
  margin-top: 40px;
  border: 2px solid #58ca7e;
  background: #58ca7e;
  padding: 2px 15px !important;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  color: #fff;
}
#fh5co-offcanvass ul li a.call-to-action:hover {
  color: #fff;
  border: 2px solid #45c46f;
  background: #45c46f;
}
#fh5co-page {
  position: relative;
  z-index: 2;
}
#fh5co-page.fh5co-offcanvass-left {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.mobile-menu-expanded #fh5co-page.fh5co-offcanvass-left {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -moz-transform: translateX(250px);
  -webkit-transform: translateX(250px);
  -ms-transform: translateX(250px);
  -o-transform: translateX(250px);
  transform: translateX(250px);
}
#fh5co-page.fh5co-offcanvass-right {
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
.mobile-menu-expanded #fh5co-page.fh5co-offcanvass-right {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -moz-transform: translateX(-250px);
  -webkit-transform: translateX(-250px);
  -ms-transform: translateX(-250px);
  -o-transform: translateX(-250px);
  transform: translateX(-250px);
}
/* NAV STYLE #1 */
.fh5co-nav-style-1,
.fh5co-nav-style-2 {
  position: absolute;
  z-index: 8;
  width: 100%;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-1,
  .fh5co-nav-style-2 {
    margin-top: 20px;
  }
}
.fh5co-nav-style-1[data-offcanvass-position="fh5co-offcanvass-left"]
  .fh5co-nav-toggle,
.fh5co-nav-style-2[data-offcanvass-position="fh5co-offcanvass-left"]
  .fh5co-nav-toggle {
  right: auto;
  top: auto;
  top: 0;
  left: 0;
  margin-top: -15px !important;
}
.fh5co-nav-style-1[data-offcanvass-position="fh5co-offcanvass-right"]
  .fh5co-nav-toggle,
.fh5co-nav-style-2[data-offcanvass-position="fh5co-offcanvass-right"]
  .fh5co-nav-toggle {
  right: auto;
  top: auto;
  top: 0;
  right: 0;
  margin-top: -15px !important;
}
.fh5co-nav-style-1 .fh5co-logo,
.fh5co-nav-style-2 .fh5co-logo {
  margin-top: 8px;
  position: relative;
}
.fh5co-nav-style-1 .fh5co-logo a,
.fh5co-nav-style-2 .fh5co-logo a {
  font-size: 25px;
  color: #fff;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 700;
}
.fh5co-nav-style-1 .fh5co-logo a:hover,
.fh5co-nav-style-1 .fh5co-logo a:focus,
.fh5co-nav-style-1 .fh5co-logo a:active,
.fh5co-nav-style-2 .fh5co-logo a:hover,
.fh5co-nav-style-2 .fh5co-logo a:focus,
.fh5co-nav-style-2 .fh5co-logo a:active {
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-1 .fh5co-logo,
  .fh5co-nav-style-2 .fh5co-logo {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-1 .fh5co-link-wrap,
  .fh5co-nav-style-2 .fh5co-link-wrap {
    display: none;
  }
}
.fh5co-nav-style-1 .fh5co-link-wrap ul,
.fh5co-nav-style-2 .fh5co-link-wrap ul {
  padding: 0;
  margin: 0;
  width: 100%;
}
.fh5co-nav-style-1 .fh5co-link-wrap ul li,
.fh5co-nav-style-2 .fh5co-link-wrap ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
}
.fh5co-nav-style-1 .fh5co-link-wrap ul li a,
.fh5co-nav-style-2 .fh5co-link-wrap ul li a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  margin: 0 15px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  padding: 10px 0;
}
@media screen and (max-width: 992px) {
  .fh5co-nav-style-1 .fh5co-link-wrap ul li a,
  .fh5co-nav-style-2 .fh5co-link-wrap ul li a {
    margin: 0 5px;
  }
}
.fh5co-nav-style-1 .fh5co-link-wrap ul li a:hover,
.fh5co-nav-style-2 .fh5co-link-wrap ul li a:hover {
  text-decoration: none;
  color: white;
}
.fh5co-nav-style-1 .fh5co-link-wrap ul li a:active,
.fh5co-nav-style-1 .fh5co-link-wrap ul li a:focus,
.fh5co-nav-style-2 .fh5co-link-wrap ul li a:active,
.fh5co-nav-style-2 .fh5co-link-wrap ul li a:focus {
  text-decoration: none;
}
.fh5co-nav-style-1 .fh5co-link-wrap ul li a.call-to-action,
.fh5co-nav-style-2 .fh5co-link-wrap ul li a.call-to-action {
  margin-top: 2px;
  border: 2px solid #58ca7e;
  background: #58ca7e;
  padding: 2px 15px !important;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  color: #fff;
}
.fh5co-nav-style-1 .fh5co-link-wrap ul li a.call-to-action:hover,
.fh5co-nav-style-2 .fh5co-link-wrap ul li a.call-to-action:hover {
  color: #fff;
  border: 2px solid #45c46f;
  background: #45c46f;
}
.fh5co-nav-style-1 .fh5co-link-wrap ul li.active a,
.fh5co-nav-style-2 .fh5co-link-wrap ul li.active a {
  color: #fff;
}
/* NAV STYLE #2 */
.fh5co-nav-style-2 {
  background: transparent;
  border: transparent;
  margin-top: 30px;
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 {
    margin-top: 20px;
  }
}
.fh5co-nav-style-2 #navbar.navbar-center {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 #navbar .navbar-nav {
    padding-top: 10px;
  }
}
.fh5co-nav-style-2 #navbar .navbar-nav.navbar-center {
  float: none !important;
  width: auto !important;
  margin: 0 auto !important;
  display: inline-block !important;
  text-align: center !important;
}
.fh5co-nav-style-2 #navbar .navbar-nav li a {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 #navbar .navbar-nav li a {
    color: #262626;
  }
}
.fh5co-nav-style-2 #navbar .navbar-nav li a:hover {
  color: white;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 #navbar .navbar-nav li a:hover {
    color: #1784fb;
  }
}
.fh5co-nav-style-2 #navbar .navbar-nav li.active {
  background: transparent;
}
.fh5co-nav-style-2 #navbar .navbar-nav li.active a {
  background: transparent;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 #navbar .navbar-nav li.active a {
    color: #1784fb;
  }
}
.fh5co-nav-style-2 #navbar .navbar-nav.fh5co-social a {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 #navbar .navbar-nav.fh5co-social li {
    display: inline-block;
  }
}
.fh5co-nav-style-2 .navbar-brand {
  display: table;
  margin-top: -10px;
  top: 0;
  position: relative;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 .navbar-brand {
    margin-top: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.fh5co-nav-style-2 .navbar-brand > span {
  background: rgba(255, 255, 255, 0.1);
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 .navbar-brand > span {
    background: rgba(0, 0, 0, 0.1);
    color: #262626 !important;
  }
}
.fh5co-nav-style-2 .navbar-brand img {
  display: table-cell;
  vertical-align: middle;
  margin-top: -10px;
}
.fh5co-nav-style-2 .fh5co-nav-toggle.active i::before,
.fh5co-nav-style-2 .fh5co-nav-toggle.active i::after {
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 .fh5co-nav-toggle.active i::before,
  .fh5co-nav-style-2 .fh5co-nav-toggle.active i::after {
    background: rgba(255, 255, 255, 0.5);
  }
}
.fh5co-nav-style-2 .fh5co-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 25px;
  height: 2px;
  color: #1784fb;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-out;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 .fh5co-nav-toggle i {
    background: rgba(255, 255, 255, 0.5);
  }
}
.fh5co-nav-style-2 .fh5co-nav-toggle i::before,
.fh5co-nav-style-2 .fh5co-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-style-2 .fh5co-nav-toggle i::before,
  .fh5co-nav-style-2 .fh5co-nav-toggle i::after {
    background: rgba(255, 255, 255, 0.5);
  }
}
/*
///////////////////////////////////////////////////
3.0 COVERS
///////////////////////////////////////////////////
*/
.fh5co-cover {
  background-image: url("header.6e0a31a3.jpg");
  position: relative;
  /*min-height: 800px;*/
}
.fh5co-section-heading-bg {
  background-color: rgba(35, 42, 56, 0.5);
  background: linear-gradient(
    rgba(35, 42, 56, 0.5),
    rgba(35, 42, 56, 0.8)
  ); /* Standard syntax */
  padding: 15px;
}
.fh5co-cover-intro-bg {
  background-color: rgba(35, 42, 56, 0.8);
  padding: 15px;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .fh5co-section-heading-bg {
    padding-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .fh5co-cover-text .container .row {
    margin: 10rem 0;
  }
}
@media screen and (max-width: 992px) {
  .fh5co-section-heading-bg {
    /*padding-bottom: 0;*/
  }

  .fh5co-cover-intro-bg {
    padding-top: 0;
  }
}
@media screen and (min-width: 992px) {
  .fh5co-section-heading-bg {
    background: linear-gradient(
      to right,
      rgba(35, 42, 56, 0.5),
      rgba(35, 42, 56, 0.8)
    ); /* Standard syntax */
  }
}
.fh5co-section-heading p {
  margin: 0;
}
.fh5co-cover .fh5co-cover-text {
  position: relative;
  z-index: 5;
}
.fh5co-cover .fh5co-cover-text .full-height {
  display: table;
  /*min-height: 800px;*/
}
.fh5co-cover .fh5co-cover-text .full-height .fh5co-cover-intro,
.fh5co-cover .fh5co-cover-text .full-height .fh5co-section-heading {
  display: table-cell;
  vertical-align: middle;
  /*min-height: 800px;*/
}
.fh5co-cover .fh5co-cover-text .cover-text-lead {
  font-size: 40px;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 700;
  color: #fff;
}
.fh5co-cover .fh5co-cover-text .cover-text-sublead {
  font-size: 16px;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  font-weight: 400;
  line-height: 26px;
  color: #fff;
}
.fh5co-cover .fh5co-cover-text .btn-outline {
  border: 2px solid #fff;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fh5co-cover .fh5co-cover-text .btn-outline:hover {
  color: #fff;
  background: #1784fb;
  border-color: #1784fb;
}
.fh5co-cover .fh5co-cover-text .btn-outline:hover,
.fh5co-cover .fh5co-cover-text .btn-outline:focus,
.fh5co-cover .fh5co-cover-text .btn-outline:active {
  outline: none;
}
/* COVER STYLE #1 */
.fh5co-cover-style-1 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  position: relative;
}
.fh5co-cover-style-1 > .fh5co-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
/* COVER STYLE #2 */
.fh5co-cover-style-2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  position: relative;
}
.fh5co-cover-style-2 > .fh5co-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
/*
///////////////////////////////////////////////////
4.0 PROJECTS
///////////////////////////////////////////////////
*/
/* PROJECTS STYLE #1 */
.fh5co-project-style-1,
.fh5co-project-style-2,
.fh5co-project-style-3,
.fh5co-project-style-4 {
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-1,
  .fh5co-project-style-2,
  .fh5co-project-style-3,
  .fh5co-project-style-4 {
    padding: 3em 0;
  }
}
.fh5co-project-style-1 .fh5co-grid {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}
.fh5co-project-style-1 .fh5co-grid > a {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  background: #1784fb;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fh5co-project-style-1 .fh5co-grid > a i {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  font-size: 40px;
  color: #fff;
}
.fh5co-project-style-1 .fh5co-grid > a:hover {
  opacity: 0.9;
}
.fh5co-project-style-1 .fh5co-grid-1 {
  height: 544px;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-1 .fh5co-grid-1 {
    height: 254px;
  }
}
.fh5co-project-style-1 .fh5co-grid-2 {
  height: 257px;
}
.fh5co-project-style-1 .fh5co-grid-3 {
  height: 257px;
}
.fh5co-project-style-2 {
  padding-bottom: 0;
}
.fh5co-project-style-2 .fh5co-projects ul {
  padding: 0;
  margin: 0;
}
.fh5co-project-style-2 .fh5co-projects ul li {
  padding: 0;
  margin: 0;
  position: relative;
  list-style: none;
  -webkit-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
  -ms-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
  -o-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.fh5co-project-style-2 .fh5co-projects ul li a {
  width: 100%;
  position: relative;
  display: block;
}
.fh5co-project-style-2 .fh5co-projects ul li a .fh5co-overlay {
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.8;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-project-style-2 .fh5co-projects ul li a .fh5co-text {
  display: table;
  width: 100%;
  position: relative;
  z-index: 2;
}
.fh5co-project-style-2 .fh5co-projects ul li a .fh5co-text .fh5co-text-inner {
  display: table-cell;
  vertical-align: middle;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-2 .fh5co-projects ul li a .fh5co-text .fh5co-text-inner {
    height: inherit;
    padding: 4em 0;
  }
}
.fh5co-project-style-2
  .fh5co-projects
  ul
  li
  a
  .fh5co-text
  .fh5co-text-inner
  h3 {
  margin-bottom: 0;
  color: #fff;
}
.fh5co-project-style-2 .fh5co-projects ul li a .fh5co-text .fh5co-text-inner p {
  font-size: 20px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.5);
}
.fh5co-project-style-2
  .fh5co-projects
  ul
  li
  a
  .fh5co-text
  .fh5co-text-inner
  p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-2
    .fh5co-projects
    ul
    li
    a
    .fh5co-text
    .fh5co-text-inner
    p {
    font-size: 16px;
    line-height: 24px;
  }
}
.fh5co-project-style-2 .fh5co-projects ul li a > .container {
  position: relative;
}
.fh5co-project-style-2 .fh5co-projects ul li a:hover .fh5co-overlay {
  opacity: 0;
}
.fh5co-project-style-3 .fh5co-project-wrap {
  position: relative;
  overflow: hidden;
}
.fh5co-project-style-3 .fh5co-project-item {
  position: relative;
  height: 350px;
  display: block;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.fh5co-project-style-3 .fh5co-project-item .fh5co-overlay,
.fh5co-project-style-3 .fh5co-project-item .fh5co-text {
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-project-style-3 .fh5co-project-item .fh5co-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #1784fb;
  opacity: 0;
  z-index: 2;
}
.fh5co-project-style-3 .fh5co-project-item .fh5co-text {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 3;
  bottom: 20px;
  opacity: 0;
  padding: 0 15px;
}
.fh5co-project-style-3 .fh5co-project-item .fh5co-text h3,
.fh5co-project-style-3 .fh5co-project-item .fh5co-text p {
  opacity: 0;
  position: relative;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-project-style-3 .fh5co-project-item .fh5co-text h3 {
  color: #fff;
  top: -30px;
  margin-bottom: 10px;
}
.fh5co-project-style-3 .fh5co-project-item .fh5co-text p {
  color: rgba(255, 255, 255, 0.6);
  top: 30px;
}
.fh5co-project-style-3 .fh5co-project-item:hover .fh5co-overlay {
  opacity: 0.9;
}
.fh5co-project-style-3 .fh5co-project-item:hover .fh5co-text {
  opacity: 1;
}
.fh5co-project-style-3 .fh5co-project-item:hover h3,
.fh5co-project-style-3 .fh5co-project-item:hover p {
  opacity: 1;
  top: 0;
}
.fh5co-project-style-4 .fh5co-heading {
  margin-bottom: 20px;
}
.fh5co-project-style-4 .fh5co-meta {
  color: #b3b3b3;
  margin-bottom: 3em;
}
.fh5co-project-style-4 .fh5co-checklist {
  padding-top: 4em;
}
.fh5co-project-style-4 .fh5co-checklist h3 {
  color: #000;
  font-size: 16px;
  margin-bottom: 20px;
}
.fh5co-project-style-4 .fh5co-checklist ul {
  padding: 0;
  margin: 0;
}
.fh5co-project-style-4 .fh5co-checklist ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
.fh5co-project-style-4 .fh5co-share {
  padding-top: 4em;
}
.fh5co-project-style-4 .fh5co-share h3 {
  color: #000;
  font-size: 16px;
  margin-bottom: 20px;
}
.fh5co-project-style-4 .fh5co-share ul {
  padding: 0;
  margin: 0;
}
.fh5co-project-style-4 .fh5co-share ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
  font-size: 15px;
  letter-spacing: 1px;
}
.fh5co-project-style-4 .fh5co-share ul li a {
  margin: 0 10px 10px 0;
}
.fh5co-project-style-4 .fh5co-navigation .fh5co-icon {
  font-size: 28px;
}
.fh5co-project-style-4 .fh5co-navigation .fh5co-prev a,
.fh5co-project-style-4 .fh5co-navigation .fh5co-next a,
.fh5co-project-style-4 .fh5co-navigation .fh5co-all a {
  font-size: 20px;
  color: #000;
}
.fh5co-project-style-4 .fh5co-navigation .fh5co-prev a:hover,
.fh5co-project-style-4 .fh5co-navigation .fh5co-next a:hover,
.fh5co-project-style-4 .fh5co-navigation .fh5co-all a:hover {
  color: #1784fb;
}
.fh5co-project-style-5 {
  background: #f7f7f7;
}
.fh5co-project-style-5 .fh5co-project-item {
  float: left;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-5 .fh5co-project-item {
    display: block;
  }
}
.fh5co-project-style-5 .fh5co-project-item .fh5co-project-description,
.fh5co-project-style-5 .fh5co-project-item .fh5co-project-figure {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-5 .fh5co-project-item .fh5co-project-description,
  .fh5co-project-style-5 .fh5co-project-item .fh5co-project-figure {
    display: block;
    width: 100%;
  }
}
.fh5co-project-style-5 .fh5co-project-item .fh5co-project-figure {
  overflow: hidden;
  position: relative;
}
.fh5co-project-style-5 .fh5co-project-item .fh5co-project-figure > div {
  position: relative;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-5 .fh5co-project-item .fh5co-project-figure {
    height: 400px;
  }
}
.fh5co-project-style-5 .fh5co-project-item .fh5co-project-description {
  background: #fff;
  padding: 4em;
}
@media screen and (max-width: 1200px) {
  .fh5co-project-style-5 .fh5co-project-item .fh5co-project-description {
    padding: 2em;
  }
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-5 .fh5co-project-item .fh5co-project-description {
    padding: 1em;
    padding-top: 30px;
  }
}
.fh5co-project-style-5 .fh5co-project-item .fh5co-project-description h2 {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
}
.fh5co-project-style-5
  .fh5co-project-item.fh5co-img-right
  .fh5co-project-figure {
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.fh5co-project-style-5
  .fh5co-project-item.fh5co-img-right
  .fh5co-project-figure
  > div {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-width: 768px) {
  .fh5co-project-style-5
    .fh5co-project-item.fh5co-img-right
    .fh5co-project-figure {
    position: relative;
    top: auto;
    bototm: auto;
    height: 400px;
  }
}
.fh5co-project-style-5 .fh5co-project-item:focus .fh5co-project-figure > div,
.fh5co-project-style-5 .fh5co-project-item:hover .fh5co-project-figure > div {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
/*
///////////////////////////////////////////////////
5.0 CONTENTS
///////////////////////////////////////////////////
*/
.fh5co-content-style-2,
.fh5co-content-style-3,
.fh5co-content-style-4,
.fh5co-content-style-5,
.fh5co-content-style-6,
.fh5co-content-style-7,
.fh5co-content-style-8,
.fh5co-content-style-9,
.fh5co-content-style-10 {
  padding: 5em 0;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-2,
  .fh5co-content-style-3,
  .fh5co-content-style-4,
  .fh5co-content-style-5,
  .fh5co-content-style-6,
  .fh5co-content-style-7,
  .fh5co-content-style-8,
  .fh5co-content-style-9,
  .fh5co-content-style-10 {
    padding: 3em 0;
  }
}
.fh5co-content-style-2 {
  background: #7dc6d9;
}
.fh5co-content-style-2 h2 {
  color: #fff;
}
.fh5co-content-style-2 p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 19px;
  line-height: 29px;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  margin-bottom: 30px;
  height: 160px;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block .fh5co-text {
  position: absolute;
  z-index: 9;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  margin-top: 30px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block .fh5co-text .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  font-size: 30px;
  color: #fff;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block .fh5co-overlay {
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1784fb;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:hover,
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:focus {
  overflow: hidden;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:hover .fh5co-overlay,
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:focus .fh5co-overlay {
  opacity: 0.8;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:hover .fh5co-text,
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:focus .fh5co-text {
  margin-top: 0px;
  opacity: 1;
}
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:hover img,
.fh5co-content-style-2 .fh5co-gallery .fh5co-link-block:focus img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.fh5co-content-style-3 .fh5co-siidear-links {
  padding: 0;
  margin: 0 0 20px 0;
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-3 .fh5co-siidear-links {
    padding-left: 20px;
  }
}
.fh5co-content-style-3 .fh5co-siidear-links li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
.fh5co-content-style-3 .fh5co-siidear-links li a {
  color: #a6a6a6;
  position: relative;
}
.fh5co-content-style-3 .fh5co-siidear-links li a:before {
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 6px;
  top: 50%;
  margin-top: -4px;
  margin-left: -17px;
  background: #e6e6e6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-content-style-3 .fh5co-siidear-links li a:hover,
.fh5co-content-style-3 .fh5co-siidear-links li a:active,
.fh5co-content-style-3 .fh5co-siidear-links li a:focus {
  color: #1a1a1a;
}
.fh5co-content-style-3 .fh5co-siidear-links li a:hover:before,
.fh5co-content-style-3 .fh5co-siidear-links li a:active:before,
.fh5co-content-style-3 .fh5co-siidear-links li a:focus:before {
  background: #1784fb;
}
.fh5co-content-style-3 .fh5co-siidear-links li.label {
  color: #cccccc;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px !important;
  display: block;
  text-align: left;
}
.fh5co-content-style-3 .fh5co-siidear-links li.active a {
  color: #1a1a1a;
}
.fh5co-content-style-3 .fh5co-siidear-links li.active a:before {
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 6px;
  top: 50%;
  margin-top: -4px;
  margin-left: -17px;
  background: #1784fb;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-content-style-4 {
  background: #1784fb;
}
.fh5co-content-style-4 .fh5co-sub {
  font-size: 15px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
}
.fh5co-content-style-4 .fh5co-heading {
  color: #fff;
}
.fh5co-content-style-4 p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 28px;
}
.fh5co-content-style-4 p:last-child {
  margin-bottom: 0;
}
.fh5co-content-style-4 .btn-outline {
  color: #fff;
  border-color: #fff;
}
.fh5co-content-style-4 .btn-outline:hover {
  background: #035fc2;
  border-color: #035fc2;
}
.fh5co-content-style-5 {
  background: #f7f7f7;
}
.fh5co-content-style-5 .fh5co-content-item {
  float: left;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-5 .fh5co-content-item {
    display: block;
  }
}
.fh5co-content-style-5 .fh5co-content-item .fh5co-content-description,
.fh5co-content-style-5 .fh5co-content-item .fh5co-content-figure {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-5 .fh5co-content-item .fh5co-content-description,
  .fh5co-content-style-5 .fh5co-content-item .fh5co-content-figure {
    display: block;
    width: 100%;
  }
}
.fh5co-content-style-5 .fh5co-content-item .fh5co-content-figure {
  overflow: hidden;
  position: relative;
}
.fh5co-content-style-5 .fh5co-content-item .fh5co-content-figure > div {
  position: relative;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-5 .fh5co-content-item .fh5co-content-figure {
    height: 400px;
  }
}
.fh5co-content-style-5 .fh5co-content-item .fh5co-content-description {
  background: #fff;
  padding: 4em;
}
@media screen and (max-width: 1200px) {
  .fh5co-content-style-5 .fh5co-content-item .fh5co-content-description {
    padding: 2em;
  }
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-5 .fh5co-content-item .fh5co-content-description {
    padding: 1em;
    padding-top: 30px;
  }
}
.fh5co-content-style-5 .fh5co-content-item .fh5co-content-description h2 {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
}
.fh5co-content-style-5
  .fh5co-content-item.fh5co-img-right
  .fh5co-content-figure {
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.fh5co-content-style-5
  .fh5co-content-item.fh5co-img-right
  .fh5co-content-figure
  > div {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-5
    .fh5co-content-item.fh5co-img-right
    .fh5co-content-figure {
    position: relative;
    top: auto;
    bototm: auto;
    height: 400px;
  }
}
.fh5co-content-style-5 .fh5co-content-item:focus .fh5co-content-figure > div,
.fh5co-content-style-5 .fh5co-content-item:hover .fh5co-content-figure > div {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.fh5co-content-style-6,
.fh5co-content-style-7 {
  background: #f7f7f7;
}
.fh5co-content-style-6 .link-block figure,
.fh5co-content-style-7 .link-block figure {
  overflow: hidden;
  margin-bottom: 20px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.fh5co-content-style-6 .link-block h3,
.fh5co-content-style-7 .link-block h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
}
.fh5co-content-style-6 .link-block p,
.fh5co-content-style-7 .link-block p {
  color: gray;
}
.fh5co-content-style-6 .link-block .fh5co-meta,
.fh5co-content-style-7 .link-block .fh5co-meta {
  color: #b3b3b3;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}
.fh5co-content-style-6 .link-block img,
.fh5co-content-style-7 .link-block img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fh5co-content-style-6 .link-block:hover img,
.fh5co-content-style-7 .link-block:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.fh5co-content-style-6 {
  background: #fff;
}
.fh5co-content-style-8 h4 {
  margin-bottom: 7px;
}
.fh5co-content-style-9 {
  background: #f7f7f7;
}
.fh5co-content-style-9 .btn-actions .or {
  padding-left: 10px;
  padding-right: 10px;
  color: #b3b3b3;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
@media screen and (max-width: 480px) {
  .fh5co-content-style-9 .btn-actions .or {
    display: block;
  }
}
/* CONTENT STYLE #10 */
.fh5co-content-style-10 {
  background: #1784fb;
}
.fh5co-content-style-10 h2 {
  margin-top: 20px;
  color: #fff;
}
.fh5co-content-style-10 h2:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .fh5co-content-style-10 h2:last-child {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}
.fh5co-content-style-10 p {
  color: #fff;
  margin-bottom: 30px;
}
.fh5co-content-style-10 p:last-child {
  margin-bottom: 0;
}
.fh5co-content-style-10 .btn-outline {
  color: #fff;
  border-color: #fff;
}
.fh5co-content-style-10 .btn-outline:hover {
  background: #035fc2;
  border-color: #035fc2;
}
/*
///////////////////////////////////////////////////
6.0 FEATURES
///////////////////////////////////////////////////
*/
/* FEATURE STYLE #1 */
.fh5co-features-style-1,
.fh5co-features-style-2,
.fh5co-features-style-3,
.fh5co-features-style-4,
.fh5co-features-style-5 {
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-features-style-1,
  .fh5co-features-style-2,
  .fh5co-features-style-3,
  .fh5co-features-style-4,
  .fh5co-features-style-5 {
    padding: 3em 0;
  }
}
.fh5co-features-style-1,
.fh5co-features-style-5 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.fh5co-features-style-1 > .fh5co-overlay,
.fh5co-features-style-5 > .fh5co-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.fh5co-features-style-1 .fh5co-heading,
.fh5co-features-style-5 .fh5co-heading {
  color: #fff;
}
.fh5co-features-style-1 h3,
.fh5co-features-style-5 h3 {
  font-weight: 400;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.fh5co-features-style-1 h3,
.fh5co-features-style-5 h3 {
  color: #fff;
}
.fh5co-features-style-1 p,
.fh5co-features-style-5 p {
  color: rgba(255, 255, 255, 0.7);
}
.fh5co-features-style-1 .fh5co-features,
.fh5co-features-style-5 .fh5co-features {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}
.fh5co-features-style-1 .fh5co-features .fh5co-feature,
.fh5co-features-style-5 .fh5co-features .fh5co-feature {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 3em;
  padding-bottom: 3em;
  text-align: center;
  flex-grow: 1;
  width: 25%;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-of-type(3n + 0),
.fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-of-type(3n + 0) {
  border-right: none;
}
.fh5co-features-style-1 .fh5co-features .fh5co-feature .icon,
.fh5co-features-style-5 .fh5co-features .fh5co-feature .icon {
  color: #fed330;
  margin-bottom: 20px;
}
.fh5co-features-style-1 .fh5co-features .fh5co-feature .icon i,
.fh5co-features-style-5 .fh5co-features .fh5co-feature .icon i {
  font-size: 40px;
}
.fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-last-child(1),
.fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-last-child(2),
.fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-last-child(3),
.fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-last-child(1),
.fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-last-child(2),
.fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-last-child(3) {
  border-bottom: none;
}
@media screen and (max-width: 768px) {
  .fh5co-features-style-1 .fh5co-features .fh5co-feature,
  .fh5co-features-style-5 .fh5co-features .fh5co-feature {
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    width: 50%;
  }

  .fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-of-type(2n + 0),
  .fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-of-type(2n + 0) {
    border-right: none !important;
  }

  .fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-last-child(3),
  .fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-last-child(3) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-last-child(1),
  .fh5co-features-style-1 .fh5co-features .fh5co-feature:nth-last-child(2),
  .fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-last-child(1),
  .fh5co-features-style-5 .fh5co-features .fh5co-feature:nth-last-child(2) {
    border-bottom: none;
  }
}
@media screen and (max-width: 480px) {
  .fh5co-features-style-1 .fh5co-features .fh5co-feature,
  .fh5co-features-style-5 .fh5co-features .fh5co-feature {
    width: 100%;
    border-right: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
}
.fh5co-features-style-2 .fh5co-feature {
  margin-bottom: 30px;
  float: left;
  clear: right;
}
.fh5co-features-style-2 .fh5co-feature .fh5co-icon {
  float: left;
  width: 20%;
}
.fh5co-features-style-2 .fh5co-feature .fh5co-icon i {
  font-size: 40px;
  color: #58ca7e;
}
.fh5co-features-style-2 .fh5co-feature .fh5co-desc {
  width: 80%;
  float: right;
}
.fh5co-features-style-2 .fh5co-feature .fh5co-desc h3 {
  margin-top: 0;
  font-size: 18px;
}
.fh5co-features-style-3 {
  background: #fafafa;
}
.fh5co-features-style-3 .box {
  padding: 30px;
  background: #fff;
  text-align: center;
  color: #7f7f7f;
  margin-bottom: 70px;
  position: relative;
}
@media screen and (max-width: 992px) {
  .fh5co-features-style-3 .box {
    min-height: inherit;
    margin-bottom: 60px;
  }
}
.fh5co-features-style-3 .box .icon {
  width: 85px;
  height: 85px;
  margin: 0 auto;
  top: 0;
  margin-top: -70px;
  margin-bottom: 30px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: table;
  text-align: center;
}
.fh5co-features-style-3 .box .icon > span {
  display: table-cell;
  vertical-align: middle;
}
.fh5co-features-style-3 .box .icon > span i {
  font-size: 40px;
  color: #fff !important;
  margin: 0;
  padding: 0;
}
.fh5co-features-style-3 .box .icon.colored-1 {
  background: #2aaf67;
}
.fh5co-features-style-3 .box .icon.colored-2 {
  background: #00bff3;
}
.fh5co-features-style-3 .box .icon.colored-3 {
  background: #f26522;
}
.fh5co-features-style-3 .box .icon.colored-4 {
  background: #e52b50;
}
.fh5co-features-style-3 .box .icon.colored-5 {
  background: #2fc5cc;
}
.fh5co-features-style-3 .box .icon.colored-6 {
  background: #6173f4;
}
.fh5co-features-style-3 .box .step-number {
  font-size: 16px;
  display: block;
  color: #d1d1d1;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}
.fh5co-features-style-3 .box h3 {
  margin: 0 0 20px 0;
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}
.fh5co-features-style-4 {
  background: #58ca7e;
}
.fh5co-features-style-4 .fh5co-section-heading .fh5co-heading {
  color: #fff;
}
.fh5co-features-style-4 .fh5co-section-heading p {
  color: rgba(255, 255, 255, 0.7);
}
.fh5co-features-style-4 .fh5co-feature-item {
  background: #fff;
  float: left;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
@media screen and (max-width: 480px) {
  .fh5co-features-style-4 .fh5co-feature-item {
    display: block !important;
  }
}
.fh5co-features-style-4 .fh5co-feature-item .fh5co-feature-text,
.fh5co-features-style-4 .fh5co-feature-item .fh5co-feature-img {
  width: 50%;
}
@media screen and (max-width: 480px) {
  .fh5co-features-style-4 .fh5co-feature-item .fh5co-feature-text,
  .fh5co-features-style-4 .fh5co-feature-item .fh5co-feature-img {
    height: inherit;
    float: none !important;
    width: 100%;
    display: block !important;
  }
}
.fh5co-features-style-4 .fh5co-feature-item .fh5co-feature-text {
  float: left;
  padding: 20px;
}
.fh5co-features-style-4
  .fh5co-feature-item
  .fh5co-feature-text
  .fh5co-feature-title {
  margin: 0 0 20px 0;
  padding: 0 0 10px 0;
  font-weight: 400;
  font-size: 20px;
  color: #000;
  position: relative;
}
.fh5co-features-style-4
  .fh5co-feature-item
  .fh5co-feature-text
  .fh5co-feature-title
  .fh5co-border {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 30px;
  background: #58ca7e;
}
.fh5co-features-style-4 .fh5co-feature-item .fh5co-feature-img {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
}
@media screen and (max-width: 480px) {
  .fh5co-features-style-4 .fh5co-feature-item .fh5co-feature-img {
    height: 200px;
    left: 0;
    top: 0;
    position: relative;
    width: 100%;
  }
}
.fh5co-features-style-5 {
  background: #232939;
}
.fh5co-features-style-5 h3 {
  color: #fff;
}
.fh5co-features-style-5 p {
  color: rgba(255, 255, 255, 0.4);
}
.fh5co-features-style-5 .fh5co-features .fh5co-feature {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-right: none !important;
  border-bottom: none !important;
}
@media screen and (max-width: 768px) {
  .fh5co-features-style-5 .fh5co-features .fh5co-feature {
    border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
    border-right: none !important;
  }
}
@media screen and (max-width: 480px) {
  .fh5co-features-style-5 .fh5co-features .fh5co-feature {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    border-bottom: none !important;
  }
}
.fh5co-features-style-5 .icon {
  color: #0099cc !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  display: table;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-features-style-5 .icon i {
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
}
/*
///////////////////////////////////////////////////
7.0 BLOGS
///////////////////////////////////////////////////
*/
/* BLOG STYLE #1 */
.fh5co-blog-style-1 {
  padding: 7em 0;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .fh5co-blog-style-1 {
    padding: 4em 0;
  }
}
.fh5co-blog-style-1 .fh5co-post {
  float: left;
  width: 100%;
  background: #fff;
  position: relative;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 20px #dfdfdf;
  -moz-box-shadow: 0px 0px 20px #dfdfdf;
  -ms-box-shadow: 0px 0px 20px #dfdfdf;
  -o-box-shadow: 0px 0px 20px #dfdfdf;
  box-shadow: 0px 0px 20px #dfdfdf;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fh5co-blog-style-1 .fh5co-post img {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-blog-style-1 .fh5co-post:hover img,
.fh5co-blog-style-1 .fh5co-post:focus img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image {
  position: relative;
  overflow: hidden;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image .fh5co-overlay {
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image .fh5co-category {
  margin: 10px 0 0 15px;
  float: left;
  z-index: 2;
  position: absolute;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image .fh5co-category > a {
  font-size: 10px;
  padding: 2px 10px;
  line-height: 1.5;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #f2f2f2;
  margin: 0 4px 4px 0;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image .fh5co-category > a:hover {
  background: #1784fb;
  border: 1px solid #1784fb;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image .fh5co-category > a:hover,
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image .fh5co-category > a:active,
.fh5co-blog-style-1 .fh5co-post .fh5co-post-image .fh5co-category > a:focus {
  text-decoration: none;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text {
  padding: 20px;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text h3 {
  font-size: 17px;
  margin-bottom: 15px;
  font-weight: 700;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text h3 a {
  color: #000;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text h3 a:hover {
  color: #1784fb;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text h3 a:hover,
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text h3 a:active,
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text h3 a:focus {
  text-decoration: none;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text p {
  font-size: 14px;
  margin-bottom: 0;
  color: #a6a6a6;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-text p:last-child {
  margin-bottom: 0 !important;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-meta {
  border-top: 1px solid #f0f0f0;
  padding: 10px 20px;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  font-family: "Arial", sans-serif;
  font-weight: 400;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-meta a {
  font-size: 13px;
  margin-right: 10px;
  margin-bottom: 20px;
  color: #b3b3b3;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-meta a i {
  display: inline-block;
  margin-right: 5px;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-meta a:hover,
.fh5co-blog-style-1 .fh5co-post .fh5co-post-meta a:active,
.fh5co-blog-style-1 .fh5co-post .fh5co-post-meta a:focus {
  text-decoration: none;
}
.fh5co-blog-style-1 .fh5co-post .fh5co-post-meta a:hover {
  color: #000;
}
.fh5co-blog-style-2 {
  background: #f7f7f7;
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-blog-style-2 {
    padding: 3em 0;
  }
}
.fh5co-blog-style-2 .link-block figure {
  overflow: hidden;
  margin-bottom: 20px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.fh5co-blog-style-2 .link-block h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
}
.fh5co-blog-style-2 .link-block:hover h3 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #1784fb;
}
.fh5co-blog-style-2 .link-block p {
  color: gray;
}
.fh5co-blog-style-2 .link-block .fh5co-meta {
  color: #b3b3b3;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}
.fh5co-blog-style-2 .link-block img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fh5co-blog-style-2 .link-block:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
/*
///////////////////////////////////////////////////
8.0 BLOGS
///////////////////////////////////////////////////
*/
.fh5co-team-style-1,
.fh5co-team-style-2,
.fh5co-team-style-3 {
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-team-style-1,
  .fh5co-team-style-2,
  .fh5co-team-style-3 {
    padding: 3em 0;
  }
}
.fh5co-team-style-1 .fh5co-person {
  position: relative;
  height: 350px;
  display: block;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.fh5co-team-style-1 .fh5co-person .fh5co-overlay,
.fh5co-team-style-1 .fh5co-person .fh5co-text {
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-team-style-1 .fh5co-person .fh5co-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #1784fb;
  opacity: 0;
  z-index: 2;
}
.fh5co-team-style-1 .fh5co-person .fh5co-text {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 3;
  bottom: 20px;
  opacity: 0;
  padding: 0 15px;
}
.fh5co-team-style-1 .fh5co-person .fh5co-text h3,
.fh5co-team-style-1 .fh5co-person .fh5co-text p {
  opacity: 0;
  position: relative;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fh5co-team-style-1 .fh5co-person .fh5co-text h3 {
  color: #fff;
  top: -30px;
  margin-bottom: 10px;
}
.fh5co-team-style-1 .fh5co-person .fh5co-text p {
  color: rgba(255, 255, 255, 0.6);
  top: 30px;
}
.fh5co-team-style-1 .fh5co-person:hover .fh5co-overlay {
  opacity: 0.9;
}
.fh5co-team-style-1 .fh5co-person:hover .fh5co-text {
  opacity: 1;
}
.fh5co-team-style-1 .fh5co-person:hover h3,
.fh5co-team-style-1 .fh5co-person:hover p {
  opacity: 1;
  top: 0;
}
.fh5co-team-style-2 {
  background: #fafafa;
}
.fh5co-team-style-2 .fh5co-person {
  background: #fff;
  text-align: center;
  padding: 30px;
  float: left;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.fh5co-team-style-2 .fh5co-person figure {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-team-style-2 .fh5co-person .fh5co-text {
  padding: 10px;
}
.fh5co-team-style-2 .fh5co-person .fh5co-text h3 {
  margin-bottom: 0;
}
.fh5co-team-style-2 .fh5co-person .fh5co-text .fh5co-meta {
  color: #b3b3b3 !important;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: block;
}
.fh5co-team-style-2 .fh5co-person:hover,
.fh5co-team-style-2 .fh5co-person:focus {
  -webkit-box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
}
.fh5co-team-style-2 .fh5co-social {
  padding: 0;
  margin: 0;
}
.fh5co-team-style-2 .fh5co-social li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.fh5co-team-style-2 .fh5co-social li a {
  color: gray;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
}
.fh5co-team-style-2 .fh5co-social li a:hover {
  color: #1784fb;
}
.fh5co-team-style-3 .person {
  text-align: center;
  margin-bottom: 40px;
  float: left;
  width: 100%;
}
.fh5co-team-style-3 .person img {
  display: block;
  margin: 0 auto 30px auto;
  height: 150px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-team-style-3 .person .name {
  font-size: 24px;
  margin-bottom: 0;
}
.fh5co-team-style-3 .person .position {
  text-transform: uppercase;
  margin-bottom: 30px;
  letter-spacing: 2px;
  color: #b3b3b3;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 12px;
}
.fh5co-team-style-3 .person .social-circle {
  padding: 0;
  margin: 0;
}
.fh5co-team-style-3 .person .social-circle li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.fh5co-team-style-3 .person .social-circle li a {
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
}
.fh5co-team-style-3 .person .social-circle li a:hover {
  color: #1784fb;
}
/*
///////////////////////////////////////////////////
9.0 FOOTERS
///////////////////////////////////////////////////
*/
.fh5co-footer-style-1,
.fh5co-footer-style-2,
.fh5co-footer-style-3 {
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-footer-style-1,
  .fh5co-footer-style-2,
  .fh5co-footer-style-3 {
    padding: 3em 0;
  }
}
.fh5co-footer-style-1 {
  padding-top: 0;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.fh5co-footer-style-1 .p-b {
  padding-bottom: 20px;
}
.fh5co-footer-style-1 .fh5co-subscribe {
  padding: 7em 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.fh5co-footer-style-1 .fh5co-subscribe > .fh5co-overlay {
  position: absolute;
  background: #000;
  opacity: 0.45;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.fh5co-footer-style-1 .fh5co-subscribe .form {
  position: relative;
  z-index: 3;
}
.fh5co-footer-style-1 .fh5co-subscribe .form h2 {
  color: #fff;
}
.fh5co-footer-style-1 .fh5co-subscribe .form p {
  color: rgba(255, 255, 255, 0.8);
}
.fh5co-footer-style-1 .fh5co-subscribe .form p:last-child {
  margin-bottom: 0;
}
.fh5co-footer-style-1 .fh5co-subscribe .form-group {
  position: relative;
}
.fh5co-footer-style-1 .fh5co-subscribe .form-group i {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -10px;
  color: #fff;
}
.fh5co-footer-style-1 .fh5co-subscribe .form-control {
  padding-left: 45px;
  padding-right: 30px;
  box-shadow: none;
  border: none;
  background: rgba(255, 255, 255, 0.15);
  height: 52px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fh5co-footer-style-1
  .fh5co-subscribe
  .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.fh5co-footer-style-1 .fh5co-subscribe .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.fh5co-footer-style-1 .fh5co-subscribe .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.fh5co-footer-style-1 .fh5co-subscribe .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.fh5co-footer-style-1 .fh5co-subscribe .form-control:focus,
.fh5co-footer-style-1 .fh5co-subscribe .form-control:active {
  background: rgba(255, 255, 255, 0.2);
}
.fh5co-footer-style-1 .fh5co-subscribe .btn {
  margin-top: 3px;
  height: 52px;
}
.fh5co-footer-style-1 .fh5co-footer-inner {
  padding: 7em 0;
  /*font-family: "Open Sans", Arial, sans-serif;*/
}
.fh5co-footer-style-1 .fh5co-footer-inner .copyright {
  margin-bottom: 20px !important;
  line-height: 1.5;
}
.fh5co-footer-style-1 .fh5co-footer-inner .fh5co-logo {
  margin-bottom: 20px;
}
.fh5co-footer-style-1 .fh5co-footer-inner .fh5co-logo a {
  padding: 15px 15px;
  background: rgba(0, 0, 0, 0.07);
  font-size: 40px;
  color: #4d4d4d;
  font-weight: 700;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-family: "Montserrat", Arial, sans-serif;
}
.fh5co-footer-style-1 .fh5co-footer-inner .fh5co-logo a:hover,
.fh5co-footer-style-1 .fh5co-footer-inner .fh5co-logo a:focus {
  text-decoration: none;
}
.fh5co-footer-style-1 .fh5co-footer-inner h3 {
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 20px;
  /*font-family: "Open Sans", Arial, sans-serif;*/
}
.fh5co-footer-style-1 .fh5co-footer-inner p {
  color: #b3b3b3;
  font-size: 15px;
  font-weight: 40;
  /*font-family: "Open Sans", Arial, sans-serif;*/
}
.fh5co-footer-style-1 .fh5co-footer-inner p:last-child {
  margin-bottom: 0;
}
.fh5co-footer-style-1 .fh5co-footer-inner .links {
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 15px;
}
.fh5co-footer-style-1 .fh5co-footer-inner .links li {
  list-style: none;
  /*font-family: "Open Sans", Arial, sans-serif;*/
}
.fh5co-footer-style-1 .fh5co-footer-inner .links li a {
  font-size: 14px;
  color: gray;
}
.fh5co-footer-style-1 .fh5co-footer-inner .links li a:hover {
  text-decoration: none;
  color: #1784fb;
}
.fh5co-footer-style-1 .fh5co-footer-inner .fh5co-social {
  text-align: right;
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .fh5co-footer-style-1 .fh5co-footer-inner .fh5co-social {
    text-align: left;
  }
}
.fh5co-footer-style-1 .fh5co-footer-inner .fh5co-social a {
  margin-right: 15px;
  margin-bottom: 10px;
  font-size: 20px;
  color: #333333;
}
.fh5co-footer-style-1 .fh5co-footer-inner .fh5co-social a:hover {
  text-decoration: none;
  color: #1784fb;
}
.fh5co-footer-style-2 {
  background: #232939;
}
.fh5co-footer-style-2 .fh5co-logo {
  color: #fff;
  font-size: 24px;
  font-family: "Montserrat", Arial, sans-serif;
  margin-bottom: 0px;
}
@media screen and (max-width: 480px) {
  .fh5co-footer-style-2 .fh5co-logo {
    text-align: center;
  }
}
.fh5co-footer-style-2 .fh5co-logo > span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 768px) {
  .fh5co-footer-style-2 .fh5co-logo > span {
    display: block;
  }
}
.fh5co-footer-style-2 a {
  color: rgba(255, 255, 255, 0.5);
}
.fh5co-footer-style-2 a:hover {
  color: white;
}
.fh5co-footer-style-2 .footer-style-2-link {
  text-align: right;
}
.fh5co-footer-style-2 .footer-style-2-link > a {
  display: none;
}
@media screen and (max-width: 992px) {
  .fh5co-footer-style-2 .footer-style-2-link {
    text-align: left;
  }
}
.fh5co-footer-style-2 .footer-style-2-link ul {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 480px) {
  .fh5co-footer-style-2 .footer-style-2-link ul {
    margin-bottom: 30px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .fh5co-footer-style-2 .footer-style-2-link ul {
    margin-bottom: 20px;
  }
}
.fh5co-footer-style-2 .footer-style-2-link ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
}
@media screen and (max-width: 480px) {
  .fh5co-footer-style-2 .footer-style-2-link ul li {
    display: block;
  }
}
.fh5co-footer-style-2 .footer-style-2-link ul li a {
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 480px) {
  .fh5co-footer-style-2 .footer-style-2-link ul li a {
    padding: 4px 0;
    display: block;
  }
}
.fh5co-footer-style-2 .footer-style-2-link ul li a:hover {
  color: white;
}
.fh5co-footer-style-3 {
  background: #262626;
  font-size: 15px;
}
.fh5co-footer-style-3 .fh5co-footer-widget {
  margin-bottom: 30px;
}
.fh5co-footer-style-3 .fh5co-logo {
  color: #fff;
  font-size: 24px;
  font-family: "Montserrat", Arial, sans-serif;
  margin-bottom: 40px;
}
.fh5co-footer-style-3 .fh5co-logo .logo {
  padding: 7px 15px;
  background: rgba(255, 255, 255, 0.1);
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.fh5co-footer-style-3 .fh5co-copyright {
  font-size: 15px;
}
.fh5co-footer-style-3 h3 {
  color: #fff;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 15px;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .fh5co-footer-style-3 h3 {
    margin-bottom: 20px;
  }
}
.fh5co-footer-style-3 p {
  color: rgba(255, 255, 255, 0.5);
}
.fh5co-footer-style-3 a {
  color: rgba(255, 255, 255, 0.3);
}
.fh5co-footer-style-3 a:hover {
  color: white;
}
.fh5co-footer-style-3 .btn {
  border-color: rgba(255, 255, 255, 0.1);
  color: gray;
}
.fh5co-footer-style-3 .fh5co-links {
  padding: 0;
  margin: 0;
}
.fh5co-footer-style-3 .fh5co-links li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
.fh5co-footer-style-3 .fh5co-links.fh5co-social .icon {
  font-size: 17px;
  margin-right: 5px;
  top: 2px;
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.fh5co-footer-style-3 .fh5co-made {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 4em;
}
.fh5co-footer-style-3 .fh5co-made p {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 24px;
}
.fh5co-footer-style-3 .fh5co-made p:last-child {
  margin-bottom: 0;
}
.fh5co-footer-style-3 .fh5co-made p .heart {
  color: #fb4f59;
}
/*
///////////////////////////////////////////////////
10.0 TESTIMONIALS
///////////////////////////////////////////////////
*/
.fh5co-testimonial-style-1,
.fh5co-testimonial-style-2 {
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-testimonial-style-1,
  .fh5co-testimonial-style-2 {
    padding: 3em 0;
  }
}
.fh5co-testimonial-style-1 {
  background: #f2f2f2;
}
.fh5co-testimonial-style-1 .box-testimony {
  margin-bottom: 2em;
  float: left;
}
.fh5co-testimonial-style-1 .box-testimony blockquote {
  padding-left: 0;
  border-left: none;
  padding: 40px;
  background: #fff;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.fh5co-testimonial-style-1 .box-testimony blockquote:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 40px;
  border-top: 15px solid black;
  border-top-color: #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.fh5co-testimonial-style-1 .box-testimony blockquote .quote {
  position: absolute;
  top: 0;
  left: 10px;
  text-align: center;
  font-size: 24px;
  line-height: 0;
  display: table;
  color: #1784fb;
  width: 56px;
  height: 56px;
  margin-top: -30px;
  background: #f2f2f2;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-testimonial-style-1 .box-testimony blockquote .quote > span {
  display: table-cell;
  vertical-align: middle;
}
.fh5co-testimonial-style-1 .box-testimony blockquote p {
  font-style: italic;
}
.fh5co-testimonial-style-1 .box-testimony .author {
  color: gray;
  margin-left: 20px;
  font-size: 15px;
}
.fh5co-testimonial-style-1 .box-testimony .author .subtext {
  display: block;
  color: #cccccc;
  font-size: 16px;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item .fh5co-name {
  margin-bottom: 30px;
  float: left;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item .fh5co-name img {
  width: 70px;
  float: left;
  margin-right: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item .fh5co-name .fh5co-meta {
  float: left;
  margin-top: 15px;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item .fh5co-name .fh5co-meta h3 {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 16px;
}
.fh5co-testimonial-style-2
  .fh5co-testimonial-item
  .fh5co-name
  .fh5co-meta
  .fh5co-company {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #cccccc;
  font-size: 11px !important;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item .fh5co-text {
  font-family: "Merriweather", Times, serif;
  font-size: 16px;
  float: left;
  width: 100%;
  clear: both;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item .fh5co-text p {
  font-style: italic;
  line-height: 28px;
}
.fh5co-testimonial-style-2 .fh5co-testimonial-item .fh5co-text p:last-child {
  margin-bottom: 0;
}
/*
///////////////////////////////////////////////////
11.0 TESTIMONIALS
///////////////////////////////////////////////////
*/
.fh5co-pricing-style-1,
.fh5co-pricing-style-2 {
  padding: 5em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-pricing-style-1,
  .fh5co-pricing-style-2 {
    padding: 3em 0;
  }
}
.fh5co-pricing-style-1 .fh5co-price {
  padding-top: 0em;
  display: block;
  font-size: 50px;
  line-height: 50px;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  color: #1784fb;
  margin-bottom: 30px;
}
.fh5co-pricing-style-1 .fh5co-price > span {
  font-size: 16px;
  line-height: 16px;
  /*font-family: "Open Sans", Arial, sans-serif;*/
  font-weight: 400;
  color: gray;
}
.fh5co-pricing-style-1 .fh5co-currency {
  vertical-align: super;
  font-size: 30px !important;
  color: #1784fb !important;
  position: relative;
}
.fh5co-pricing-style-1 .fh5co-pricing-item {
  display: block;
  color: #333333;
  float: left;
  width: 100%;
  text-align: center;
  background: #fff;
  padding: 40px 10px;
  margin-bottom: 1.5em;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-pricing-style-1 .fh5co-pricing-item.pricing-feature {
  border-top: 10px solid #1784fb;
  z-index: 9;
}
.fh5co-pricing-style-1 .fh5co-pricing-item p:last-child {
  margin-bottom: 0;
}
.fh5co-pricing-style-1 .fh5co-pricing-item:hover,
.fh5co-pricing-style-1 .fh5co-pricing-item:focus,
.fh5co-pricing-style-1 .fh5co-pricing-item:active {
  text-decoration: none;
  outline: none;
}
.fh5co-pricing-style-1 .fh5co-pricing-item .fh5co-pricing-item-lead {
  font-size: 12px;
  font-family: "Montserrat", Arial, sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.fh5co-pricing-style-1 .fh5co-pricing-item .fh5co-pricing-item-text {
  font-size: 16px;
  color: #a6a6a6;
}
.fh5co-pricing-style-1 .fh5co-pricing-item:hover,
.fh5co-pricing-style-1 .fh5co-pricing-item.active {
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  margin-top: -10px;
}
@media screen and (max-width: 768px) {
  .fh5co-pricing-style-1 .fh5co-pricing-item:hover,
  .fh5co-pricing-style-1 .fh5co-pricing-item.active {
    margin-top: 0px;
  }
}
.fh5co-pricing-style-2 {
  background: #58ca7e;
}
.fh5co-pricing-style-2 .fh5co-heading {
  color: #fff;
}
.fh5co-pricing-style-2 p {
  color: rgba(255, 255, 255, 0.7);
}
.fh5co-pricing-style-2 .pricing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto 3em;
}
.fh5co-pricing-style-2 .pricing-item {
  font-family: "Montserrat", Arial, sans-serif;
  padding: 2em 3em;
  margin: 1em;
  color: #262b38;
  background: #fff;
  cursor: default;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}
@media screen and (min-width: 992px) {
  .fh5co-pricing-style-2 .pricing-item {
    margin: 1.5em 0;
  }

  .fh5co-pricing-style-2 .pricing-item.pricing-item--featured {
    z-index: 10;
    margin: 0;
    font-size: 1.15em;
  }
}
.fh5co-pricing-style-2 .pricing-title {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
}
.fh5co-pricing-style-2 .pricing-price {
  font-size: 5em;
  font-weight: 800;
  color: #58ca7e;
  position: relative;
  z-index: 100;
}
.fh5co-pricing-style-2 .pricing-currency {
  font-size: 0.5em;
  vertical-align: super;
}
.fh5co-pricing-style-2 .pricing-period {
  font-size: 16px;
  display: inline-block;
  padding: 0 0 0 0.5em;
  color: #cccccc;
}
.fh5co-pricing-style-2 .pricing-sentence {
  margin: 0 0 1em 0;
  padding: 0 0 0.5em;
  font-size: 16px;
  text-align: center;
  color: #999999;
}
.fh5co-pricing-style-2 .pricing-feature-list {
  font-size: 0.95em;
  margin: 0;
  padding: 1.5em 0.5em 2.5em;
  list-style: none;
}
.fh5co-pricing-style-2 .pricing-feature {
  padding: 0.15em 0;
}
.fh5co-pricing-style-2 .pricing-action {
  font-weight: bold;
  margin-top: auto;
  padding: 1em 2em;
  color: #fff;
  border-radius: 5px;
  background: #6ed19c;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.fh5co-pricing-style-2 .pricing-action:hover,
.fh5co-pricing-style-2 .pricing-action:focus {
  background-color: #4f5f56;
}
/*
///////////////////////////////////////////////////
12.0 MISCELLANEOUS
///////////////////////////////////////////////////
*/
/* Burger Menu */
.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: rgba(255, 255, 255, 0.5);
}
.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 25px;
  height: 2px;
  color: #1784fb;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.fh5co-nav-toggle i::before {
  top: -7px;
}
.fh5co-nav-toggle i::after {
  bottom: -7px;
}
.fh5co-nav-toggle:hover i::before {
  top: -10px;
}
.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}
.fh5co-nav-toggle.active i {
  background: transparent;
}
.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.fh5co-nav-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  margin-top: 12px;
  display: none;
  height: 44px;
  width: 44px;
  border-bottom: none !important;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
  }
}
/*# sourceMappingURL=cards.css.map */

/*# sourceMappingURL=index.4e5f1ad4.css.map */
